import { useEffect } from 'react'
import WarningIcon from '@mui/icons-material/Warning'
import toast from 'react-hot-toast'

export const useNotificationToast = (
  message: string,
  id: string,
  condition?: boolean,
  dependency?: any
) => {
  useEffect(() => {
    if (condition) {
      toast(message, {
        // icon: <WarningIcon/>,
        id,
      })
    } else {
      toast.dismiss(id)
    }
  }, dependency)

}
