import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import { useState, useEffect, useMemo, useCallback} from 'react'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { GroupAndDate, ContactDetails } from '@/components/booking/steps'
import { ProductKey, ProductName } from '@/types/index'
import { useAppStore } from '@/hooks/useStore'
import ChickenBooster from './steps/ChickenBooster'

// const steps = ['1', '2', '3']

type Props = {
  product?: ProductName
}

const BookingStepper = ({ product }: Props) => {
  const [activeStep, setActiveStep] = useState(0)

  const isAdrenalinePack = !product

  // This triggers everytime the locale changes, why? Without memo the app gets stucked
  useMemo(() => {
    // If we don't pass product as props we default to combo adrenaline pack - both products
    const selectedProducts: ProductKey[] = isAdrenalinePack ? ['DUNGEON', 'EXPERIMENT'] : [product]

    useAppStore.setState(() => ({
      selectedProducts,
      isAdrenalinePack
    }))
  }, [product])

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  // Temp hack, i will use the standard routes instead of this stepper to allow browser back buttons
  // Then i wont need this and can use router
  const handleBack = (toStart?: boolean) => {
    setActiveStep(prevActiveStep => (toStart ? 0 : prevActiveStep - 1))
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activeStep])

  // TODO: move stepper to separate component
  return (
    <Box sx={{ marginTop: '30px', paddingBottom: '20px' }}>
      <Box maxWidth="600px" mx="auto">
        {/* <Stepper activeStep={activeStep}>
          {steps.map(label => {
            const stepProps: { completed?: boolean } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel />
              </Step>
            )
          })}
        </Stepper> */}
      </Box>
      <Box>
        {/* TODO: when more products are added, add a step to choose product */}
        {/* {activeStep === 0 && <ChooseProduct />} */}

        {activeStep === 0 && <GroupAndDate product={product} nextStep={handleNext} isAdrenalinePack={isAdrenalinePack} setActiveStep={setActiveStep}/>}
        {activeStep === 1 && <ChickenBooster prevStep={handleBack} nextStep={handleNext} />}
        {activeStep === 2 && <ContactDetails prevStep={toStart => handleBack(toStart)} />}
      </Box>
    </Box>
  )
}

export default BookingStepper
