import { fetcher } from 'pages/api/fetcher'
import { useEffect, useState } from 'react'
import { ProductKey } from '../types'

export const useVouchers = (allSelectedProducts: ProductKey[]) => {
  const [validVouchers, setValidVouchers] = useState<string[]>([])

  useEffect(() => {
    const fetchValidVouchers = async () => {
      try {
        const validVouchersData = await fetcher('getValidVouchers', {
          allSelectedProducts,
        })

        setValidVouchers(validVouchersData.data.validVouchers)
      } catch (error) {
        // TODO: handle error
        console.error('Error fetching valid vouchers:', error)
      }
    }

    fetchValidVouchers()
  }, [])

  return validVouchers
}
