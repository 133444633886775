import { useAppStore } from '@/hooks/useStore'
import { Typography, Box } from '@mui/material'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { ProductKey } from '@/types/index'
import { createProductName } from '@/utils/index'
import config from '../../../config'
import { ButtonPrimary } from '../reusables/ButtonPrimary'

interface Props {
  fullyBooked: boolean
  product: ProductKey,
  comboCustomForm?: boolean
}

export const SlotSelectionInfo = ({ fullyBooked, product, comboCustomForm }: Props) => {
  const state = useAppStore(state => state)

  const isAdrenalinePack = state.isAdrenalinePack
  const { groupSize } = state.products[product as ProductKey]

  const router = useRouter()
  const { locale } = router
  const productConfig = config[product as ProductKey]
  const booking = useTranslations('Booking')

  const productName = createProductName(product, locale!)
  const upsellProductName = createProductName(
    product === 'DUNGEON' ? 'EXPERIMENT' : 'DUNGEON',
    locale!
  )
  const upsellLink =
    product === 'DUNGEON'
      ? 'https://thrillparkprague.com/horror-escape-game.html'
      : 'https://thrillparkprague.com/dungeon.html'

  const biggerGroup = groupSize >= productConfig.MULTIPLE_SLOT_TRESHOLD
  const text =
    groupSize >= productConfig.MULTIPLE_SLOT_TRESHOLD && groupSize <= productConfig.MAX_GROUP_SIZE && !comboCustomForm
      ? booking('multipleSlotText')
      : booking('maxGroupSizeText')

  const displayBigGroupText = biggerGroup || fullyBooked

  // TODO: refactor, two components? One as a wrapper and then but inside the content
  return (
    <Box sx={{ display: 'flex', maxWidth: '450px', margin: '0 auto' }}>
      {displayBigGroupText && (
        <Typography
          variant="h5"
          component="h6"
          sx={{
            fontSize: '1.1rem',
            background: '#3b0505',
            padding: '10px 40px',
            border: '1px solid #ec2424',
            display: 'inline-block',
            margin: '0 auto',
            marginTop: '20px',
            textAlign: 'center',
          }}
        >
          {!fullyBooked && text}
          {/* Dont display upsell for adrenaline pack */}
          {fullyBooked && !isAdrenalinePack && (
            <>
              {`${productName} ${booking('experineceFullyBooked')}`}
              <br />
              {`${booking('upsellText')}:`}
              <Link href={upsellLink} style={{ textDecoration: 'none' }}>
                <ButtonPrimary text={`${upsellProductName}`} customTopMargin="20px" />
              </Link>
            </>
          )}
          {fullyBooked && isAdrenalinePack && `${booking('comboProductBookedOut')}`}
        </Typography>
      )}
    </Box>
  )
}
