import Typography from '@mui/material/Typography'
import { useTranslations } from 'next-intl'
import { useForm, SubmitHandler } from 'react-hook-form'
// import { useAppStore } from 'hooks/useStore'

const ChooseProduct = () => {
  const booking = useTranslations('Booking')
  const { register, handleSubmit } = useForm()

  const onSubmit: SubmitHandler<any> = () => {
    // IMPLEMENT CHOOSING A PRODUCT with useAppStore
  }

  return (
    <div>
      <Typography variant="h1" component="h1" gutterBottom>
        {booking('chooseProduct')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input {...register('product', { required: true, maxLength: 30 })} />
        <button type="submit">Submit</button>
      </form>
    </div>
  )
}

export default ChooseProduct
