import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { csCZ, enUS } from '@mui/x-date-pickers/locales'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { minDatePickerDate, maxDatePickerDate } from 'utils/dates'
import { isToday, addDays } from 'date-fns'
import { Box, TextField } from '@mui/material'
import { useAppStore } from 'hooks/useStore'
import { useRouter } from 'next/router'
import { Container } from '@mui/system'
import { ProductKey } from '@/types/index'
import { updateDatePickerDate } from '@/hooks/utils/storeHelpers'

interface Props {
  product: ProductKey
  loading: boolean
}

const BookingDatePicker = ({ product, loading }: Props) => {
  const datePickerDate = useAppStore(state => state.products[product as ProductKey].datePickerDate)

  const { locale } = useRouter()

  const datePickerLocaleText =
    locale === 'cs'
      ? csCZ.components.MuiLocalizationProvider.defaultProps.localeText
      : enUS.components.MuiLocalizationProvider.defaultProps.localeText

  const dateIsToday = isToday(datePickerDate)

  const handleDate = (selectedDate: Date, changeDay?: 'next' | 'prev') => {
    if (loading) return

    let newDate: Date

    if (changeDay) {
      newDate =
        changeDay === 'next'
          ? addDays(selectedDate || datePickerDate, 1)
          : addDays(selectedDate || datePickerDate, -1)
    } else {
      newDate = selectedDate
    }

    updateDatePickerDate(product, newDate)
  }

  return (
    <Container maxWidth="sm" sx={{ marginBottom: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '20px',
          padding: '10px',
          margin: '0 auto',
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={datePickerLocaleText}
          dateFormats={{}}
        >
          <ArrowCircleLeftIcon
            sx={{
              marginRight: '20px',
              color: dateIsToday ? 'secondary.main' : 'primary.main',
              cursor: 'pointer',
            }}
            onClick={() => (dateIsToday ? null : handleDate(datePickerDate, 'prev'))}
          />
          <MobileDatePicker
            value={datePickerDate}
            disablePast
            inputFormat="EEEE, dd, MMMM"
            maxDate={maxDatePickerDate}
            minDate={minDatePickerDate}
            closeOnSelect
            // TODO: update to new MUI, its slotProps, componentProps are depracated
            componentsProps={{
              actionBar: {
                actions: ['today'],
              },
            }}
            // showToolbar={false}
            // TODO: could use setDate(newDate!)
            onChange={newDate => handleDate(newDate as Date)}
            renderInput={params => (
              // To add icon to MobileDatePicker https://stackoverflow.com/questions/69804943/how-to-add-icon-to-mobiledatepicker-in-mui-v5
              <TextField
                {...params}
                sx={{
                  backgroundColor: 'primary.main',
                  margin: '0',
                  svg: { color: 'primary.main' },
                  cursor: 'pointer',
                }}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
          <ArrowCircleRightIcon
            sx={{ marginLeft: '20px', color: 'primary.main', cursor: 'pointer' }}
            onClick={() => handleDate(datePickerDate, 'next')}
          />
        </LocalizationProvider>
      </Box>
    </Container>
  )
}

export default BookingDatePicker

// renderInput={({ inputRef, InputProps }) => (
//   // To add icon to MobileDatePicker https://stackoverflow.com/questions/71520368/material-ui-datepicker-custom-renderinput
//   <Box ref={inputRef}>
//     <InputAdornment position="start">
//       <EventIcon sx={{ color: 'primary.main', cursor: 'pointer' }} />
//       <Typography sx={{ color: 'red' }}>Select date</Typography>
//     </InputAdornment>
//   </Box>
// )}
