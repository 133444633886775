import { Box } from '@mui/system'

export const SlotGridEmptyTime = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: {
          md: '1.2rem',
          xs: '0.9rem',
          xxs: '0.7rem',
        },
      }}
    />
  )
}