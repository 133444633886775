import { Typography, Button, TextField, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import Paper from '@mui/material/Paper'
import { Theme } from '@mui/system'

interface StyledGridProps {
  free: boolean
}

interface SlotTimeProps {
  free: boolean
  groupIsSelected: boolean
}

interface SlotItemProps {
  free: boolean
  selected: boolean
}

interface GroupSizeSelectButtonProps {
  selected: boolean
  smalltext?: boolean
}

const slotColor = (free: boolean, selected: boolean, theme: Theme): string => {
  if (selected) return theme.palette.success.main
  if (free) return theme.palette.primary.main

  return theme.palette.secondary.main
}

const slotTimeColor = (free: boolean, groupIsSelected: boolean, theme: Theme): string => {
  if (!groupIsSelected) return '#3d3d3d'
  if (free) return 'white'

  return 'grey'
}

export const SlotTimeFontSize = {
  md: '1.5rem',
  sm: '1.2rem',
  xs: '1rem',
  xxs: '0.8rem',
}

export const SlotPriceFontSize = {
  md: '0.8rem',
  xs: '0.7rem',
  xxs: '0.5rem',
}

export const SlotTime = styled(Typography, {
  shouldForwardProp: prop => prop !== 'groupIsSelected' && prop !== 'free',
})<SlotTimeProps>(({ free, groupIsSelected, theme }) => ({
  color: slotTimeColor(free, groupIsSelected, theme),
  textAlign: 'left',
  marginBottom: '0.5rem',
}))

export const SlotPrice = styled(Typography, {
  shouldForwardProp: prop => prop !== 'free',
})<StyledGridProps>(({ free, theme }) => ({
  color: free ? 'white' : 'grey',
  textAlign: 'left',
  lineHeight: '1',
}))

export const SlotItem = styled(Paper, {
  shouldForwardProp: prop => prop !== 'free' && prop !== 'selected',
})<SlotItemProps>(({ free, selected, theme }) => ({
  backgroundColor: slotColor(free, selected, theme),
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: free ? 'pointer' : 'not-allowed',
  height: '100%',
}))

export const SkeletonSlotTime = styled(Typography)(() => ({
  color: 'grey',
  textAlign: 'left',
  marginBottom: '0.5rem',
}))

export const SkeletonSlotItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: 'not-allowed',
}))

export const StyledIncrDectButtton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey[50]),
  backgroundColor: 'black',
  borderColor: 'red',
  '&:hover': {
    backgroundColor: 'black',
    borderColor: 'red',
  },
  '&.Mui-disabled': {
    borderColor: 'red',
  },
}))

export const StyledGroupSizeInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 0,
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '& input': {
      borderLeft: '1px solid red',
      textAlign: 'center',
      width: 60,
      color: 'white',
      fontSize: '1.4rem',
    },
  },
})

export const StyledStepSubhedline = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'normal',
}))

export const StyledMainBookingProductDesc = styled(Box)(({ theme }) => ({
  color: 'white',
  fontFamily: 'AbelRegular',
  textTransform: 'uppercase',
  fontWeight: 'normal',
  [theme.breakpoints.up('xxs')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.9rem',
    marginTop: '0.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
    marginTop: '0.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
}))

export const StyledMainBookingSubheadline = styled(Box)(({ theme }) => ({
  fontFamily: 'AbelRegular',
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 'normal',
  fontSize: '2rem',
  [theme.breakpoints.up('xxs')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.2rem',
    marginBottom: '0.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    marginBottom: '0.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.8rem',
  },
}))

export const GroupSizeSelectButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'smalltext',
})<GroupSizeSelectButtonProps>(({ theme, selected, smalltext }) => ({
  color: selected ? theme.palette.primary.main : 'white',
  borderRadius: '50%',
  backgroundColor: 'black',
  borderColor: selected ? theme.palette.primary.main : 'black',
  '&:hover': {
    backgroundColor: 'black',
    borderColor: 'red',
  },

  [theme.breakpoints.up('xxs')]: {
    flexWrap: 'wrap',
    marginRight: '5px',
    fontSize: smalltext ? '0.7rem' : '1rem',
  },
  [theme.breakpoints.up('xs')]: {
    marginRight: '10px',
    fontSize: smalltext ? '0.8rem' : '1.2rem',
    marginBottom: '0.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: smalltext ? '0.8rem' : '1.5rem',
    marginBottom: '0.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: smalltext ? '1rem' : '1.8rem',
  },
}))

// Relative fontSize to H2 from theme
export const GroupSizeSubHeadline = styled(Typography)(({ theme }) => ({
  marginTop: '0.5rem',
  color: 'white',
  fontFamily: 'ChangaOne',
  fontSize: '1.2rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.9rem',
  },
}))

export const OverLayBox = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 2,
  textAlign: 'center',
}))
