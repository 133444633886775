import React from 'react'
import { useTranslations } from 'next-intl'
import {
  Box,
  Container,
  Typography,
  IconButton,
  SvgIcon,
  Grid,
  List,
  ListItem,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useGtm } from 'hooks/useGtm'
import { BloodText } from '@/components/reusables/BloodText'
import CheckMark from '@/public/images/tp_web2018_checkmark_red.svg'
import { ButtonPrimary } from '@/components/reusables/ButtonPrimary'

interface Props {
  prevStep: () => void
  nextStep: () => void
}

const ChickenBooster = ({ prevStep, nextStep }: Props) => {
  const booster = useTranslations('ChickenBooster')
  const buttons = useTranslations('Buttons')
  useGtm('chickenBooster')

  const handleClick = () => {
    nextStep()
  }

  return (
    <Container maxWidth="sm">
      <Box onClick={() => prevStep()}>
        <IconButton color="inherit" aria-label="back" sx={{ padding: 0 }}>
          <ArrowBackIcon />
          <Typography variant="h6" color="text.primary" sx={{ marginLeft: '10px' }}>
            {buttons('back')}
          </Typography>
        </IconButton>
      </Box>
      <Box mt={3}>
        <Typography variant="h2" component="h2" gutterBottom align="center" px={3}>
          {booster('title2')}
          <br />
          <BloodText text={booster('title3')} />
        </Typography>
      </Box>
      <Box mt={5}>
        <Typography variant="h6">{booster('title4')}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box>
            <List>
              <ListItem>
                <SvgIcon sx={{ fontSize: '4rem' }}>
                  <CheckMark />
                </SvgIcon>
                <Typography variant="body2" ml={2}>
                  {booster('condition1')}
                </Typography>
              </ListItem>
              <ListItem>
                <SvgIcon sx={{ fontSize: '4rem' }}>
                  <CheckMark />
                </SvgIcon>
                <Typography variant="body2" ml={2}>
                  {booster('condition2')}
                </Typography>
              </ListItem>
              <ListItem>
                <SvgIcon sx={{ fontSize: '4rem' }}>
                  <CheckMark />
                </SvgIcon>
                <Typography variant="body2" ml={2}>
                  {booster('condition3')}
                </Typography>
              </ListItem>
              <ListItem>
                <SvgIcon sx={{ fontSize: '4rem' }}>
                  <CheckMark />
                </SvgIcon>
                <Typography variant="body2" ml={2}>
                  {booster('condition4')}
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
      <ButtonPrimary text={buttons('next')} handleClick={nextStep} />
    </Container>
  )
}

export default ChickenBooster
