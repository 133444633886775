import moment from 'moment'
import { isBefore, addMinutes, startOfDay, addHours, endOfDay, add } from 'date-fns'
import { DateFormat, formatDateTime } from 'utils/dates'
import { ProductKey, Slot, freeBusyQueryParsed } from '../../../../types/index'
import config from '../../../../../config'
import { calculateSlotDutation } from './bookingUtils'

export const slotIsFree = (
  checkedSlot: Date,
  busySlots: freeBusyQueryParsed,
  product: ProductKey,
  slotDuration: number
) => {
  const bookingBuffer = config[product as ProductKey].BOOKING_BUFFER

  const endOfCheckedSlot = add(checkedSlot, {
    minutes: Number(slotDuration! as number),
  })

  const queryTime = moment(Date.now()).add(bookingBuffer, 'minutes')

  return busySlots.every(busySlot => {
    // RETURNS TRUE IF THE SLOT IS FREE-BUSY RANGE , '[)' means including/excluding, undefined is skipping parametr
    return (
      !moment(checkedSlot).isBetween(busySlot.start, busySlot.end, undefined, '[)') &&
      // EXTRA CONDITION TO MAKE SURE, THAT INSERTING THE SLOT IN BETWEEN OF SLOT's START AND END MAKES THE SLOT BUSY (15:15 start of slot, 15:30 free slot led to slot beeing FREE)
      !moment(endOfCheckedSlot).isBetween(busySlot.start, busySlot.end) &&
      // IF THE SLOT IS BEETWEN NOW AND BOOKING BUFFER WE MARK IT AS BUSY
      !moment(checkedSlot).isBefore(queryTime)
    )
  })
}

export const createTimeSlots = (
  dayStart: Date,
  dayEnd: Date,
  product: ProductKey,
  slotDuration: number
): Date[] => {
  // for some reason if i want to import calculateSlotDutation fn and use it i get error in webpack
  // TODO: enum
  // Adding 15 minutes buffer for experiment so it starts later than Dungeon every quarter of an hour
  const calculateStartTime = product === 'EXPERIMENT' ? addMinutes(dayStart, 15) : dayStart

  // TO_DO: fix this, process.env will be laways defined in envs
  // Plus its not safe and can create infinite loops
  const slots = []
  let startTime = calculateStartTime
  while (isBefore(startTime, dayEnd)) {
    slots.push(startTime)
    // ESCAPE GAME WILL HAVE DIFFERENT SLOT DURATION, THIS NEEDS TO BE SET IN SOME CONTEXT
    startTime = addMinutes(startTime, slotDuration)
  }

  return slots
}

export const createDummyTimeSlots = (product: ProductKey): String[] => {
  const dayStart = startOfDay(new Date())
  const dayEnd = endOfDay(new Date())
  // TO_DO: fix this, process.env will be laways defined in envs
  // this didnt work   const slotDuration = Number(process.env.DEFAULT_SLOT_DURATION ?? '')
  const slotDuration = product === 'DUNGEON' ? 30 : 60
  const slots = []
  let startTime = addHours(dayStart, 16)

  while (isBefore(startTime, dayEnd)) {
    slots.push(formatDateTime(startTime, DateFormat.Time))
    // ESCAPE GAME WILL HAVE DIFFERENT SLOT DURATION, THIS NEEDS TO BE SET IN SOME CONTEXT
    startTime = addMinutes(startTime, slotDuration)
  }

  return slots
}

const findFirstSlotOrSelectDefault = (obj: Slot, defaultStartingTime: string) => {
  // Retur first free slot or the slot with DEFAULT_STARTING_TIME = eg. 16:00
  return obj.time === defaultStartingTime || obj.free === true
}

export const selectStartingSlot = (
  slots: {
    id: string
    date: Date
    time: string
    free: boolean
    pricing: Record<string, number>
  }[],
  defaultStartingTime: string
) => {
  const index = slots.findIndex(slot => findFirstSlotOrSelectDefault(slot, defaultStartingTime))

  if (index !== -1) {
    return slots.slice(index)
  }

  return []
}
