import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslations } from 'next-intl'
import { StyledStepSubhedline } from '../styled'

interface Props {
  text: string
  step: string
}

export const BookingStepHeadline = ({ text, step }: Props) => {
  const booking = useTranslations('Booking')

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography variant="h3" component="h3" gutterBottom>
      <StyledStepSubhedline component="span">{booking('headlineStep')} {step}: </StyledStepSubhedline>
      {text}
      </Typography>
    </Box>
  )
}
