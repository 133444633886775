import * as React from 'react'
import { Grid, CircularProgress } from '@mui/material'
import { createDummyTimeSlots } from 'pages/api/booking/utils/dateTimeUtils'
import { SkeletonSlotItem, SkeletonSlotTime, SlotTimeFontSize } from 'components/styled/index'
import { useTranslations } from 'next-intl'
import { Box, Container } from '@mui/system'
import { ProductName } from '@/types/index'
import { ButtonPrimary } from '../reusables/ButtonPrimary'
import { SlotGridEmptyTime } from './SlotGridEmptyTime'

interface Props {
  product: ProductName
}

const SlotGridSkeleton = ({ product }: Props) => {
  const buttons = useTranslations('Buttons')
  const dummyTimes = createDummyTimeSlots(product)

  const skeletonSlots = []
  for (let i = 0; i < dummyTimes.length; i++) {
    skeletonSlots.push(
      <Grid item xs={3} key={i}>
        <SkeletonSlotItem>
          <SkeletonSlotTime
            variant="h5"
            sx={{
              fontSize: SlotTimeFontSize,
            }}
          >
            {dummyTimes[i]}
          </SkeletonSlotTime>
          <SlotGridEmptyTime />
        </SkeletonSlotItem>
      </Grid>
    )
  }
  return (
    <Container maxWidth="sm" sx={{ position: 'relative', paddingBottom: '20px' }}>
      <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 12, sm: 12, md: 12 }}>
        {skeletonSlots?.map(slot => {
          return slot
        })}
      </Grid>
      <CircularProgress
        sx={{
          position: 'absolute',
          left: '0',
          right: '0',
          top: '0',
          bottom: '0',
          margin: 'auto',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'bottom',
          maxWidth: '300px',
          marginBottom: '20px',
          marginTop: '10px',
        }}
        mx="auto"
      >
        <Box>
          <ButtonPrimary text={buttons('next')} isDisabled />
        </Box>
      </Box>
    </Container>
  )
}

export default SlotGridSkeleton
