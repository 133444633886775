import { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useAppStore } from '@/hooks/useStore'
import { Container } from '@mui/system'
import { filteredDoubleSlots } from '@/utils/index'
import { nanoid } from 'nanoid'
import { FreeSlots, GoogleCalendarEvent, ProductKey, Slot } from '../../types/index'
import config from '../../../config'
import { SlotGridOverlay } from './SlotGridOverlay'
import { SlotGridCell } from './SlotGridCell'

interface Props {
  slots: FreeSlots
  handleClick: (slot: Slot, slots: Slot[]) => void
  fullybooked: boolean
  pricing: number[]
  product: ProductKey
  freeSlotsPlus3: GoogleCalendarEvent[] | []
}

export const SlotGrid = ({
  slots,
  handleClick,
  fullybooked,
  pricing,
  product,
  freeSlotsPlus3,
}: Props) => {
  // Google calendar event sets the minimum group size for which should be each slot available
  const groupSize = useAppStore(state => state.products[product as ProductKey].groupSize)
  const productConfig = config[product as ProductKey]
  const calendarConfig = useAppStore(state => state.products[product as ProductKey].calendarConfig)
  const isMinGroupSizeSatisfied = groupSize >= calendarConfig?.minGroupSize

  const slotsToSelect =
    groupSize >= productConfig.MULTIPLE_SLOT_TRESHOLD ? filteredDoubleSlots(slots) : slots

  const [price, setPrice] = useState(0)

  const groupIsSelected = groupSize > 0

  useEffect(() => {
    // No internet - leads to fail in API call , leads to pricing undefined leads to FE error
    if (!pricing) return
    setPrice(Math.ceil(pricing[groupSize]))
  }, [groupSize])

  return (
    <Box style={{ position: 'relative' }}>
      <SlotGridOverlay
        fullyBooked={fullybooked}
        groupIsSelected={groupIsSelected}
        isMinGroupSizeSatisfied={isMinGroupSizeSatisfied}
      />
      <Container maxWidth="sm" sx={{ paddingBottom: '20px' }}>
        <Grid
          container
          spacing={{ xxs: 1, md: 1 }}
          columns={{ xxs: 12, sm: 12, md: 12 }}
          sx={{ position: 'relative' }}
          alignItems="stretch"
        >
          {slotsToSelect?.map(slot => {
            return (
              <SlotGridCell
                slot={slot}
                product={product}
                freeSlotsPlus3={freeSlotsPlus3}
                groupIsSelected={groupIsSelected}
                isMinGroupSizeSatisfied={isMinGroupSizeSatisfied}
                slotsToSelect={slotsToSelect}
                handleClick={handleClick}
                pricePerPerson={price}
                key={nanoid()}
              />
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}
