import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslations } from 'next-intl'
import { Box, Container, Typography, IconButton } from '@mui/material'
import { useAppStore } from '@/hooks/useStore'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ErrorFallback } from 'components/reusables/ErrorFallback'
import { useGtm } from 'hooks/useGtm'
import BookingRecap from '../BookingRecap'
import BookingForm from '../BookingForm'

interface Props {
  prevStep: (toStart?: boolean) => void
}

const ContactDetails = ({ prevStep }: Props) => {
  const booking = useTranslations('Booking')
  const buttons = useTranslations('Buttons')
  const confirmation = useTranslations('Confirmation')
  const selectedProducts = useAppStore(state => state.selectedProductsSortedByStartsAt)

  useGtm('contactForm')

  return (
    <Container maxWidth="sm">
      <Box onClick={() => prevStep()}>
        <IconButton color="inherit" aria-label="back" sx={{ padding: 0 }}>
          <ArrowBackIcon />
          <Typography variant="body2" color="text.primary" sx={{ marginLeft: '10px' }}>
            {buttons('back')}
          </Typography>
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'start' }} px={1} mt={3}>
        <Typography variant="h2" component="h2" gutterBottom align="center">
          {booking('contactDetails')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => prevStep(true)}>
          <BookingForm />
        </ErrorBoundary>
      </Box>
      <Box>
        <Typography variant="h2" textAlign="center" mb={4} mt={5}>
          {confirmation('bookingSummary')}:
        </Typography>
        {selectedProducts().map(product => (
          <BookingRecap product={product} key={product} />
        ))}
      </Box>
    </Container>
  )
}

export default ContactDetails
