import React from 'react'
import { useAppStore } from '@/hooks/useStore'
import { Box } from '@mui/material'
import { ProductName } from 'types'
import { useGtm } from 'hooks/useGtm'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '@/components/reusables/ErrorFallback'
import BookingDateAndGroup from '../BookingDateAndGroup'

interface Props {
  nextStep: () => void
  // Hacky way: If i don't pass product param I assume it muset be combo pack, if I pass it, its the product according to param. Therefore product can be undefined
  product: ProductName | undefined
  setActiveStep: (step: number) => void
  isAdrenalinePack?: boolean
}

const GroupAndDate = ({ product, nextStep, isAdrenalinePack, setActiveStep }: Props) => {
  useGtm('chooseDate')
  const state = useAppStore(state => state)

  const disableDungeon = state.products.EXPERIMENT.groupSize > 5 && isAdrenalinePack
  const disableExperiment = state.products.DUNGEON.groupSize > 5 && isAdrenalinePack

  return (
    <Box>
      {!product ? (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => setActiveStep(1)}>
            {!disableExperiment && (
              <BookingDateAndGroup
                nextStep={nextStep}
                product="EXPERIMENT"
                combo={isAdrenalinePack}
                comboCustomForm={disableDungeon}
              />
            )}
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => setActiveStep(1)}>
            {!disableDungeon && (
              <BookingDateAndGroup
                nextStep={nextStep}
                product="DUNGEON"
                combo={isAdrenalinePack}
                comboCustomForm={disableExperiment}
              />
            )}{' '}
          </ErrorBoundary>
        </>
      ) : (
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => setActiveStep(1)}>
          <BookingDateAndGroup nextStep={nextStep} product={product} />
        </ErrorBoundary>
      )}
    </Box>
  )
}

export default GroupAndDate
