import React, { useState, useEffect } from 'react'
import { useAppStore } from 'hooks/useStore'
import { Typography, Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { MuiTelInput } from 'mui-tel-input'
import { useRouter } from 'next/router'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonLoader } from 'components/reusables/ButtonLoader'
import { useTranslations } from 'next-intl'
import { useErrorHandler } from 'react-error-boundary'
import { useVouchers } from '@/hooks/useVouchers'
import { createProductName } from 'utils'
import { fetcher } from '../../pages/api/fetcher'
import { useValidations } from '../../hooks/useValidations'
import { allowedCountries } from '../../utils/countryCodes'

interface Props {
  customBooking?: boolean
}
const BookingForm = ({ customBooking }: Props) => {
  // TODO: in case of error in useVouchers API call i render empy array, validation is not working then and its not possible to submit form if voucher code is selected
  // TODO: in that case i need to display error message and log it to slack ideally
  const state = useAppStore()
  const { selectedProducts } = state
  const validVouchers = useVouchers(selectedProducts)
  const { validationSchema } = useValidations(validVouchers)
  const [loading, setLoading] = useState(false)
  const [isVoucher, setIsVoucher] = useState(false)
  const [formFilled, setFormFilled] = useState(false)
  const handleError = useErrorHandler()
  const router = useRouter()
  const { locale } = useRouter()

  const isAdrenalinePack = useAppStore(state => state.isAdrenalinePack)
  const isLocalhost = window.location.hostname === 'localhost' && window.location.port === '3000'

  const {
    register,
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    context: { isVoucher },
    defaultValues: {
      fullName: isLocalhost ? 'TEST vojta' : '',
      email: isLocalhost ? 'vkrajsa@gmail.com' : '',
      phoneNumber: isLocalhost ? '+43 3123 31231' : '+420',
      voucherCode: '',
      note: '',
    },
  })

  const booking = useTranslations('Booking')

  const productName = createProductName(selectedProducts, locale!)

  const dungeonSelected = (selectedProducts as ('DUNGEON' | 'EXPERIMENT')[]).includes('DUNGEON')

  // If there is both Dungeon and Experiment selected we want to override Exp deposit requirement to Dungeon's deposit
  // Alternatively we could choose the higher deposit requirement (if EXP has required and DNG soft, we would go with required)
  const depositRequirement = dungeonSelected
    ? state.products.DUNGEON.calendarConfig.deposit
    : state.products.EXPERIMENT.calendarConfig.deposit

  const onSubmitHandler: SubmitHandler<any> = async values => {
    useAppStore.setState({ bookingForm: values })
    setFormFilled(true)
  }

  // QUESTION: how to wait in onSubmitHandler to useAppStore.setState so i can call createReservation() there instead of here in useEffect?
  useEffect(() => {
    if (!formFilled) return
    const createReservation = async () => {
      setLoading(true)
      try {
        // TODO: any
        let bookingInformation = []
        let depositPaymentUrl = ''

        if (customBooking) {
          // TODO: solve booking request
          // Single fetch for customBooking
          const response = await fetcher('bookingRequest', {
            product: selectedProducts[0],
            allSelectedProducts: state.selectedProducts,
            bookingForm: state.bookingForm,
            productData: state.products[selectedProducts[0]],
            userTimeZone: state.userTimeZone,
            isAdrenalinePack,
          })
          bookingInformation = [response?.data?.bookingInformation]
        } else {
          const responses = []

          for (const product of selectedProducts) {
            const response = await fetcher('createReservation', {
              product,
              allSelectedProducts: state.selectedProducts,
              bookingForm: state.bookingForm,
              productData: state.products[product],
              depositRequirement,
              userTimeZone: state.userTimeZone,
              isAdrenalinePack,
            })
            responses.push(response)
          }

          bookingInformation = responses.map(response => response?.data?.bookingInformation)

          // When deposit is false, I do not need payment link
          const getPaymentLink = await fetcher(
            'getPaymentLink',
            undefined,
            undefined,
            bookingInformation
          )
          depositPaymentUrl = getPaymentLink?.data?.paymentLink
        }

        useAppStore.setState({ bookingInformation, depositPaymentUrl })

        router.push({
          pathname: '/confirmation',
          query: { customBooking },
        })
      } catch (error) {
        handleError(error)
      }
      setLoading(false)
    }

    createReservation()
  }, [formFilled])

  const handleCheckbox = async () => {
    if (isVoucher) resetField('voucherCode')
    setIsVoucher(!isVoucher)
  }

  const buttonText = customBooking ? booking('customFormButton') : booking('formButton')

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} sx={{ width: '100%' }}>
      <Box display="flex" flexDirection="column" justifyContent="center" mx="auto">
        <TextField
          {...register('fullName', { required: 'This field is required' })}
          id="outlined-basic"
          // TODO: PUT TO THEME
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: 'black',
            },
          }}
          label={booking('fullName')}
          variant="filled"
        />

        <Typography paragraph sx={{ color: 'primary.main' }}>
          {errors.fullName?.message}
        </Typography>

        <TextField
          {...register('email', { required: 'This field is required' })}
          // TODO: PUT TO THEME
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: 'black',
            },
          }}
          label={booking('email')}
          variant="filled"
        />

        <Typography paragraph sx={{ color: 'primary.main' }}>
          {' '}
          {errors.email?.message}
        </Typography>

        <Controller
          name="phoneNumber"
          control={control}
          render={({ field, fieldState }) => (
            <MuiTelInput
              {...field}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'black',
                },
              }}
              onlyCountries={allowedCountries}
            />
          )}
        />

        <Typography paragraph sx={{ color: 'primary.main' }}>
          {errors.phoneNumber?.message}
        </Typography>

        {customBooking && (
          <TextField
            {...register('note', {})}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: 'black',
              },
            }}
            label={booking('note')}
            variant="filled"
          />
        )}

        {!customBooking && (
          <FormControlLabel
            control={<Checkbox onChange={handleCheckbox} />}
            label={`${booking('voucherCheckbox')} ${productName}`}
          />
        )}

        {isVoucher && (
          <>
            {' '}
            <TextField
              {...register('voucherCode', { required: 'This field is required' })}
              id="outlined-basic"
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'black',
                },
              }}
              label={booking('voucherCode')}
              variant="filled"
            />
            <Typography paragraph sx={{ color: 'primary.main' }}>
              {errors.voucherCode?.message}
            </Typography>
          </>
        )}
        <ButtonLoader text={buttonText} loading={loading} />
      </Box>
    </Box>
  )
}

export default BookingForm
