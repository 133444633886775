import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslations } from 'next-intl'
import { ProductName } from 'types'
import { createProductName } from '@/utils/index'
import { useRouter } from 'next/router'
import { BloodText } from '../reusables/BloodText'
import { StyledMainBookingProductDesc, StyledMainBookingSubheadline } from '../styled'

interface Props {
  product: ProductName
  comboCustomForm?: boolean
  combo?: boolean
}

export const BookingMainHeadline = ({ product, comboCustomForm, combo }: Props) => {
  const booking = useTranslations('Booking')
  const { locale } = useRouter()
  const productName = createProductName(product, locale!)
  const isEscapeGame = product === 'EXPERIMENT'

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
      {comboCustomForm ? (
        <Typography variant="h1" component="h1" gutterBottom>
          <StyledMainBookingSubheadline> {booking('bookingHeadlineCustomCombo')}</StyledMainBookingSubheadline>
          <BloodText text={booking('bookingHeadlineCustomComboProduct')} />
        </Typography>
      ) : (
        <Typography variant="h1" component="h1" gutterBottom>
          <StyledMainBookingSubheadline> { combo ?  booking('bookingHeadlineCustomCombo') : booking('bookingHeadline')} </StyledMainBookingSubheadline>
          <BloodText text={productName} />
          {isEscapeGame && (
            <StyledMainBookingProductDesc>{booking('escapeGame')}</StyledMainBookingProductDesc>
          )}
        </Typography>
      )}
    </Box>
  )
}
