import { Typography, Box } from '@mui/material'
import { useTranslations } from 'next-intl'
import { GroupSizeSubHeadline, OverLayBox } from '../styled'

interface Props {
  fullyBooked: boolean
  groupIsSelected: boolean
  isMinGroupSizeSatisfied: boolean
}

export const SlotGridOverlay = ({ groupIsSelected, isMinGroupSizeSatisfied, fullyBooked }: Props) => {
  const booking = useTranslations('Booking')

  // Two scenarios - day is full or user hasn't selected a group size
  if (!fullyBooked && groupIsSelected && isMinGroupSizeSatisfied) return null

  const minSizeNotSatisfied = groupIsSelected && !isMinGroupSizeSatisfied

  return (
    <OverLayBox>
      {fullyBooked || minSizeNotSatisfied ? (
        <Typography variant="h2" component="h2">
          {booking('fullyBooked')}
        </Typography>
      ) : (
        <>
          <Typography variant="h2" component="h2">
            {booking('selectGroupSize')}
          </Typography>
          <GroupSizeSubHeadline>{booking('selectGroupSizeSubheadline')}</GroupSizeSubHeadline>
        </>
      )}
    </OverLayBox>
  )
}
