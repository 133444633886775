import { useEffect, useState, useMemo } from 'react'
import { fetcher } from 'pages/api/fetcher'
import { FreeSlots, GoogleCalendarEvent, ProductKey, Slot } from 'types'
import { useErrorHandler } from 'react-error-boundary'
import { AppState } from './useStore'
import { resetStoreGroupSize, updateStoreCalendarConfig } from './utils/storeHelpers'
// TODO: type the hook
// no need to send here whole formState , date would do enough...

export const useFetchSlots = (state: AppState, product: ProductKey) => {
  const [freeSlots, setFreeSlots] = useState<FreeSlots | []>([])
  const [pricing, setPricing] = useState<[]>([])
  const [freeSlotsPlus3, setFreeSlotsPlus3] = useState<GoogleCalendarEvent[] | []>([])
  const [loading, setLoading] = useState(false)

  const handleError = useErrorHandler()

  const fullyBooked = useMemo(
    () => freeSlots?.every((slot: Slot) => !slot.free) && freeSlots.length > 0,
    [freeSlots]
  )

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const { data } = await fetcher('freeSlots', {
          productData: state.products[product],
          product,
          isAdrenalinePack: state.isAdrenalinePack,
          userTimeZone: state.userTimeZone,
        })

        const { freeSlots, pricing, freeSlotsPlus3, minGroupSizeFeatureActive, calendarConfig } =
          data

        setFreeSlots(freeSlots)
        setPricing(pricing)
        setFreeSlotsPlus3(freeSlotsPlus3)

        if (minGroupSizeFeatureActive) resetStoreGroupSize(product)
        updateStoreCalendarConfig(product, calendarConfig)
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [state.products[product].datePickerDate])

  return { freeSlots, setFreeSlots, loading, fullyBooked, pricing, freeSlotsPlus3 }
}
