import { CountryCode } from 'libphonenumber-js'

export const allowedCountries: CountryCode[] = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  'AL',
  'AR',
  'AM',
  'AU',
  'AZ',
  'BY',
  'BA',
  'BR',
  'CA',
  'CL',
  'CN',
  'CO',
  'CR',
  'CU',
  'DO',
  'EC',
  'EG',
  'GE',
  'GI',
  'GT',
  'IN',
  'IL',
  'JP',
  'JO',
  'KZ',
  'KR',
  'KW',
  'KG',
  'LB',
  'MK',
  'MT',
  'MX',
  'MD',
  'MC',
  'MA',
  'NZ',
  'PY',
  'PE',
  'PH',
  'PR',
  'QA',
  'RU',
  'SA',
  'SG',
  'ZA',
  'TW',
  'TJ',
  'TH',
  'TN',
  'TR',
  'TM',
  'UA',
  'AE',
  'US',
  'UY',
  'UZ',
  'VE',
  'VN',
]
