import { useEffect } from 'react'
import { useTranslations } from 'next-intl'
import { Box } from '@mui/material'
import { useAppStore } from 'hooks/useStore'
import { resetSelectedSlots, updateStoreGroupSize } from '@/hooks/utils/storeHelpers'
import { BookingStepHeadline } from '@/components/headlines/BookingStepHeadline'
import { ProductKey } from '@/types/index'
import { SlotSelectionInfo } from './SlotSelectionInfo'
import { GroupSizeSelector } from './GroupSizeSelector'
import { GroupSizeCounter } from './GroupSizeCounter'

// TODO: move to ENVS to limit this?
const minGroupSize = 2

interface Props {
  fullyBooked: boolean
  product: ProductKey
  comboCustomForm?: boolean
}

const BookingGroupSize = ({ fullyBooked, product, comboCustomForm }: Props) => {
  const booking = useTranslations('Booking')

  const groupSize = useAppStore(state => state.products[product].groupSize)

  const displayCounter = groupSize > 5

  useEffect(() => {
    resetSelectedSlots(product)
  }, [groupSize, product])

  return (
    <Box maxWidth="600px" mx="auto" mb={4}>
      <BookingStepHeadline text={booking('groupSize')} step="1" />
      <GroupSizeSelector
        groupSize={groupSize}
        minGroupSize={minGroupSize}
        onGroupSizeChange={groupSize => updateStoreGroupSize(product, groupSize)}
      />
      {displayCounter && <GroupSizeCounter groupSize={groupSize} product={product}/>}
      <SlotSelectionInfo fullyBooked={fullyBooked} product={product} comboCustomForm={comboCustomForm}/>
    </Box>
  )
}

export default BookingGroupSize
