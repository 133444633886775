import { Container } from '@mui/material'
import { useTranslations } from 'next-intl'
import { GroupSizeSelectButton } from '../styled'

interface GroupSizeSelectorProps {
  minGroupSize: number
  groupSize: number
  onGroupSizeChange: (groupSize: number) => void
}

export const GroupSizeSelector = ({
  minGroupSize,
  onGroupSizeChange,
  groupSize,
}: GroupSizeSelectorProps) => {
  const btns = useTranslations('Buttons')

  const handleNumberClick = (number: number) => {
    onGroupSizeChange(number)
  }

  const isSelected = (key: number, moreButton?: boolean) => {
    if (moreButton) return groupSize >= 6
    return key === groupSize
  }

  const renderNumberButtons = () => {
    const buttons = []

    for (let i = minGroupSize; i <= 6; i++) {
      if (i < 6) {
        buttons.push(
          <GroupSizeSelectButton
            selected={isSelected(i)}
            key={i}
            onClick={() => handleNumberClick(i)}
            variant="outlined"
          >
            {i}
          </GroupSizeSelectButton>
        )
      } else if (i === 6) {
        buttons.push(
          <GroupSizeSelectButton
            key={i}
            onClick={() => handleNumberClick(i)}
            variant="outlined"
            selected={isSelected(i, true)}
            smalltext
          >
            {btns('more')}
          </GroupSizeSelectButton>
        )
      }
    }

    return buttons
  }

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {renderNumberButtons()}
    </Container>
  )
}
