import * as yup from 'yup'
import { matchIsValidTel } from 'mui-tel-input'
import { useTranslations } from 'next-intl'

const isValidNumber = (value: string) => {
  return matchIsValidTel(value)
}

// codes that do not require payment
const partnerCodes = ['JLFREE', 'NODEPO', 'SKVELECESKO']

// Not a hook just a function...
export const useValidations = (validVouchers: string[]) => {
  const validations = useTranslations('Validations')

  const validationSchema = yup.object().shape({
    email: yup.string().email(validations('invalidEmail')).required(validations('requiredEmail')),
    fullName: yup.string().required(validations('requiredName')),
    phoneNumber: yup
      .string()
      .required()
      .test('is-valid-phone', validations('invalidPhone'), value => {
        return isValidNumber(value)
      }),
    voucherCode: yup.string().when('$isVoucher', {
      is: (isVoucher: boolean) => isVoucher,
      then: () =>
        yup
          .string()
          .required(validations('requiredVoucherCode'))
          .min(6, validations('requiredValidVoucherCode'))
          .test(
            'validate-voucher',
            validations('requiredValidVoucherCode'),
            async function (voucherCode) {
              if (!voucherCode) {
                // Don't perform the validation if voucherCode is not provided
                return true
              }

              // Call the validateVoucher function to check if the voucher code is valid
              return validVouchers.includes(voucherCode) || partnerCodes.includes(voucherCode)
            }
          ),
      otherwise: () => yup.string().notRequired(),
    }),
  })
  return { validationSchema }
}

// This must start with use otherwise I cant call the useTranslations...
export const useMemoryPackValidationSchema = () => {
  const validations = useTranslations('Validations')

  return yup
    .object({
      code: yup
        .string()
        .required(validations('wrongCode'))
        .min(6, validations('wrongCode'))
        .max(7, validations('wrongCode')),
    })
    .required()
}
