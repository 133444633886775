import { Grid } from '@mui/material'
import { useAppStore } from '@/hooks/useStore'
import { SlotItem, SlotTimeFontSize, SlotTime } from '@/components/styled/index'
import { calculateBufferCondition, DateFormat, formatDateTime } from '@/utils/dates'
import { getSlotDatesOfOtherProduct } from '@/utils/index'
import { GoogleCalendarEvent, ProductKey, Slot } from '../../types/index'
import { SlotGridPrice } from './SlotGridPrice'

interface Props {
  slot: Slot
  isMinGroupSizeSatisfied: boolean
  slotsToSelect: Slot[]
  handleClick: (slot: Slot, slots: Slot[]) => void
  pricePerPerson: number
  groupIsSelected: boolean
  product: ProductKey
  freeSlotsPlus3: GoogleCalendarEvent[] | []
}

export const SlotGridCell = ({
  slot,
  isMinGroupSizeSatisfied,
  slotsToSelect,
  handleClick,
  pricePerPerson,
  freeSlotsPlus3,
  groupIsSelected,
  product,
}: Props) => {
  const selectedSlots = useAppStore(state => state.products[product as ProductKey].selectedSlots)
  const selectedProducts = useAppStore(state => state.selectedProducts)
  const products = useAppStore(state => state.products)
  const groupSize = useAppStore(state => state.products[product as ProductKey].groupSize)
  const calendarConfig = useAppStore(state => state.products.DUNGEON.calendarConfig)

  const increasedCapacity = calendarConfig.increasedCapacity === 'active'

  // TODO: move to utils fns
  // Extracted to improve readability and testability
  const isSlotReservedFor3PlusGroup = (slotTime: string): boolean => {
    return freeSlotsPlus3.some(freeSlot => {
      const freeSlotTime = formatDateTime(freeSlot.start.dateTime, DateFormat.Time)
      return freeSlotTime === slotTime
    })
  }

  const plusFreeSlotCondition = (() => {
    const reservedFor3Plus = isSlotReservedFor3PlusGroup(slot.time)
    if (reservedFor3Plus) {
      // If its reserved for 3+ group, then the group size must be 3 or more
      return groupSize >= 3
    }
    // If its not reserved for 3+ group, then the group size can be anything
    return true
  })()

  const slotDateOfOtherProduct = getSlotDatesOfOtherProduct(selectedProducts, product, products)
  const bufferConditionFullfilled = slotDateOfOtherProduct
    ? calculateBufferCondition(slot, slotDateOfOtherProduct, increasedCapacity)
    : true

  const isFree =
    isMinGroupSizeSatisfied && slot.free && bufferConditionFullfilled && plusFreeSlotCondition

  const isSelected = (slotId: string) => {
    return selectedSlots.some(selectedSlot => selectedSlot.id === slotId)
  }

  return (
    <Grid item xxs={3} key={slot.id}>
      <SlotItem
        selected={isSelected(slot.id)}
        free={isFree}
        onClick={() => (isFree ? handleClick(slot, slotsToSelect) : null)}
      >
        <SlotTime
          variant="h5"
          gutterBottom
          groupIsSelected={groupIsSelected}
          free={isFree}
          sx={{
            fontSize: SlotTimeFontSize,
          }}
        >
          {slot.time}
        </SlotTime>

        <SlotGridPrice
          pricePerPerson={pricePerPerson}
          groupIsSelected={groupIsSelected}
          isFree={isFree}
        />
      </SlotItem>
    </Grid>
  )
}
