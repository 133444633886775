import { Typography, Box } from '@mui/material'
import { useTranslations } from 'next-intl'
import { SlotPrice, SlotPriceFontSize } from '../styled'
import { SlotGridEmptyTime } from '../skeletons/SlotGridEmptyTime'

interface Props {
  pricePerPerson: number
  groupIsSelected: boolean
  isFree: boolean
}

export const SlotGridPrice = ({ pricePerPerson, groupIsSelected, isFree }: Props) => {
  const index = useTranslations('Index')
  const booking = useTranslations('Booking')

  if (!groupIsSelected) {
    return <SlotGridEmptyTime />
  }

  return (
    <SlotPrice
      free={isFree}
      variant="subtitle1"
      sx={{
        fontSize: SlotPriceFontSize,
      }}
    >
      {isFree ? (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            component="span"
            sx={{
              fontSize: {
                md: '1.2rem',
                xs: '0.9rem',
                xxs: '0.7rem',
              },
            }}
            mr={0.3}
          >
            {pricePerPerson}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontSize: {
                md: '0.7rem',
                xs: '0.5rem',
                xxs: '0.4rem',
              },
              lineHeight: 0.5,
            }}
          >
            <sup>
              <span>{`${index('currency')}/`}</span>
              <span
                style={{
                  display: 'block',
                }}
              >
                {index('pricePerPerson')}
              </span>
            </sup>
          </Typography>
        </Box>
      ) : (
        <Typography
          component="span"
          sx={{
            fontSize: {
              md: '1rem',
              xs: '0.8rem',
              xxs: '0.5rem',
            },
          }}
        >
          {booking('soldOutText')}
        </Typography>
      )}
    </SlotPrice>
  )
}
