import AddIcon from '@mui/icons-material/Add'
import { useTheme, Container, ButtonGroup } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import { StyledGroupSizeInput, StyledIncrDectButtton } from '@/components/styled'
import { useAppStore } from '@/hooks/useStore'
import { ProductKey } from '@/types/index'


interface Props {
  groupSize: number
  product: ProductKey
}
export const GroupSizeCounter = ({ groupSize, product }: Props) => {
  const theme = useTheme()

  const { increaseGroupSize, decreaseGroupSize } = useAppStore(state => state)

  // TODO: ADD TO ENVS
  const minGroupSize = 2
  const maxGroupSize = 50

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
      <ButtonGroup>
        <StyledIncrDectButtton
          onClick={() => decreaseGroupSize(product)}
          disabled={groupSize === minGroupSize}
        >
          <RemoveIcon
            style={{
              color:
                groupSize === minGroupSize
                  ? theme.palette.secondary.light
                  : theme.palette.primary.main,
              fontSize: '1.4rem',
            }}
          />
        </StyledIncrDectButtton>
        <StyledGroupSizeInput className="noMarginBottom" size="small" value={groupSize} />
        <StyledIncrDectButtton
          onClick={() => increaseGroupSize(product)}
          disabled={groupSize === maxGroupSize}
        >
          <AddIcon
            style={{
              color:
                groupSize === maxGroupSize
                  ? theme.palette.secondary.light
                  : theme.palette.primary.main,
              fontSize: '1.4rem',
            }}
          />
        </StyledIncrDectButtton>
      </ButtonGroup>
    </Container>
  )
}
